import { observer } from 'mobx-react-lite'
import React from 'react'
import { Spinner } from 'react-bootstrap'
import { Navigate, Route, Routes } from 'react-router-dom'
import APP_ROUTES from '../constants/app-routes'
import AuthenticatedRouting from '../constants/authenticated-routing'
import useStore from '../hooks/use-store'
import AuthenticatedLayout from '../layouts/authenticated-layout'

function AuthenticatedContent() {

    const { authStore } = useStore()

    if (authStore.isLoadingUserData) {
        return (
            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    return (
        <AuthenticatedLayout>
            <Routes>
                {AuthenticatedRouting.map(route => {
                    return <Route key={route.path} path={route.path} element={route.element} />
                })}
                <Route path="*" element={<Navigate replace to={APP_ROUTES.HOME} />} />
            </Routes>
        </AuthenticatedLayout>
    )
}

export default observer(AuthenticatedContent)
