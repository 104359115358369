import axios from "axios";
import { TRANSACTIONS, TRANSACTIONS_GET_DELETED } from "../constants/service-resources";
import IPaginatedResponse from "../interfaces/service/models/IPaginatedResponse";
import ITransaccion from "../interfaces/service/models/ITransaccion";
import qs from 'qs'
import ITransaccionRequest from "../interfaces/service/requests/ITransaccionRequest";

export async function loadTransactions(
    page: number = 1,
    orderBy: string = "fecha",
    orderDirection: string = "desc",
    filter?: any) {

    const params: any = {
        page,
        orderBy,
        orderDirection,
    }

    if (filter) {
        params.filter = filter
    }

    const result = await axios.get<IPaginatedResponse<ITransaccion>>(TRANSACTIONS, {
        params: params,
        paramsSerializer: (pr) => qs.stringify(pr)
    })
    return result.data
}

export async function postTransaction(data: ITransaccionRequest) {
    const result = axios.post(TRANSACTIONS, data)
    return result
}

export async function deleteTransaction(idTranssaction: number){
    const result = axios.delete(`${TRANSACTIONS}/${idTranssaction}`)
    return result
}

export async function getDeletedTransactions(page: number = 1) {

    const result = await axios.get<IPaginatedResponse<ITransaccion>>(TRANSACTIONS_GET_DELETED, {
        params: {
            page
        }
    })
    return result.data
}