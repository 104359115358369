// usos de useQuery que pueden ser llamados desde varias partes de la app

import { useQuery } from "react-query";
import api from "../api/api";
import RQ from "./queries-constants";

export const useGetMetodosEs = () => useQuery(
    RQ.GET_METODOS_ES, api.metodosES.getMeotdosEs,
    {
        refetchOnWindowFocus: false
    }
)

export const useResumenTransacciones = () => useQuery(
    RQ.GET_RESUMEN_TRANSACCIONES, api.resumen.getResumenTransacciones,
    {
        refetchOnWindowFocus: false
    }
)

export const useTransaccionesEliminadas = (page: number) => useQuery(
    [RQ.DELETED_TRANSACTION, page], () => api.transacciones.getDeletedTransactions(page),
    {
        refetchOnWindowFocus: false
    }
)

export const useGetSaldos = () => useQuery(
    RQ.GET_SALDOS, api.saldos.getSaldos,
    {
        refetchOnWindowFocus: false
    }
)
