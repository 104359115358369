import React from 'react'
import { Form } from 'react-bootstrap'
import { FieldError } from 'react-hook-form'

type ErrorFeedbackPropsTypes = {
    error?: FieldError
}

const ErrorFeedback = ({error}: ErrorFeedbackPropsTypes) => {
    return (
        <Form.Control.Feedback type="invalid">
            {error && error.message}
        </Form.Control.Feedback>
    )
}

export default ErrorFeedback
