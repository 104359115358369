import { observer } from "mobx-react-lite"
import { Alert, Button, Form, Spinner } from "react-bootstrap"
import useStore from "../../hooks/use-store"
import './login.css'
import { useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorFeedback from "../../components/error-feedback";
import yup from "../../yup";

const schema = yup.object({
    email: yup.string().email().required().label("Correo"),
    password: yup.string().required().label("Contraseña"),
}).required();

interface FormFields extends yup.Asserts<typeof schema> { }

const Login = () => {

    const { authStore } = useStore()


    const { register, handleSubmit, formState: { errors } } = useForm<FormFields>({
        resolver: yupResolver(schema)
    });

    const [loginError, setLoginError] = useState<string | undefined>(undefined)
    const [isLoginIn, setIsLoginIn] = useState(false)

    const onSubmit = handleSubmit(async (data) => {
        try {
            setIsLoginIn(true)
            setLoginError(undefined)
            await authStore.login(data.email, data.password)
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 401) {
                    setLoginError("Usuario o contraseña incorrectos")
                    return
                }
                setLoginError(error.response.statusText)
            }
            setLoginError((error as Error).message)
            throw error
        } finally {
            setIsLoginIn(false)

        }
    })

    return (
        <div className="w-100 h-100" style={{backgroundImage: "url(/img/login-bg.jpg)",backgroundSize: "cover"}}>
            <div className="w-100 d-flex justify-content-center pt-5">
                <div className="card p-4 login-card bg-light" >
                    <Form onSubmit={onSubmit}>
                        <div className="text-center">
                            <img src="img/snclogo.svg" alt="logo-snc" width={150} height={150} />
                        </div>
                        <Form.Group className="mb-3" controlId="email-input" >
                            <Form.Label>Correo</Form.Label>
                            <Form.Control
                                type="email"
                                disabled={isLoginIn}
                                isInvalid={!!errors.email}
                                {...register("email")} />
                            <ErrorFeedback error={errors.email} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control
                                type="password"
                                disabled={isLoginIn}
                                isInvalid={!!errors.password}
                                {...register("password", { required: true })} />
                            <ErrorFeedback error={errors.password} />
                        </Form.Group>
                        <div className="text-center">
                            {isLoginIn ?
                                <Spinner animation="border" variant="primary" />
                                :
                                <Button variant="primary" type="submit">
                                    Ingresar
                                </Button>
                            }
                        </div>
                    </Form>
                    {loginError && <Alert variant="danger" className="mt-2">
                        {loginError}
                    </Alert>}
                </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
                Copyright &#169; SNC Pharma {new Date().getFullYear()}
            </div>
        </div>
    )
}

export default observer(Login)