import React from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import ITransaccion from '../../../../interfaces/service/models/ITransaccion'
import { useDeleteTranssaction } from '../../../../react-query/mutations'

type ActionButtonsPropsType = {
    transsaction: ITransaccion,
    onTranssactionDeleted: () => void
}

const ActionButtons = ({ transsaction,onTranssactionDeleted }: ActionButtonsPropsType) => {
    const deleteMutation = useDeleteTranssaction()

    async function handleRemoveClick(idTranssaction: number) {
        try {
            const resp = window.confirm("¿Desea eliminar la transacción?")
            if (resp) {
                await deleteMutation.mutateAsync(idTranssaction)
                toast("Eliminada correctamente", { type: "success" })
                onTranssactionDeleted()
            }
        } catch (error) {
            toast("Ocurrió un problema", { type: "error" })
            throw error
        }
    }

    if (deleteMutation.isLoading) {
        return <Spinner animation="border" variant="primary" />
    }

    return (
        <>
            <button
                className="btn"
                onClick={() => handleRemoveClick(transsaction.id)}>
                <i className="fas fa-trash" />
            </button>
        </>
    )
}

export default ActionButtons
