import ResultsTable from "./results-table"


const DeletedTransactionsPage = () => {

    return (
        <div className="pt-2">
            <h1 className="mb-4">Transacciones eliminadas</h1>
            <ResultsTable />
        </div>
    )
}

export default DeletedTransactionsPage