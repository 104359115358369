import * as auth from './auth'
import * as transacciones from './transacciones'
import * as metodosES from './metodos-es'
import * as resumen from './resumen'
import * as reportes from './reportes'
import * as saldos from './saldos'

const api = {
    auth,
    transacciones,
    metodosES,
    resumen,
    reportes,
    saldos
}

export default api 