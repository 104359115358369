import React from 'react'
import { OverlayTrigger, Tooltip, Spinner, Table } from 'react-bootstrap'
import Paginator from '../../../../components/paginator'
import IPaginatedResponse from '../../../../interfaces/service/models/IPaginatedResponse'
import ITransaccion from '../../../../interfaces/service/models/ITransaccion'
import formatNumber from '../../../../utils/format-number'
import formatDate from '../../../../utils/format-date'
import SortableTableHeader from '../../../../components/sortable-table-header'
import SortType from '../../../../interfaces/app/SortType'
import useStore from '../../../../hooks/use-store'
import ActionButtons from './action-buttons'
import APP_SETTINGS from '../../../../constants/app-settings'

type ResultsTablePropsType = {
    paginatedResult?: IPaginatedResponse<ITransaccion>
    isLoading: boolean
    isFetching: boolean,
    page: number,
    sortColumn?: string,
    sortType: "asc" | "desc",
    onPageChange: (selectedItem: { selected: number }) => void
    onSortTable: (dataKey: string, sortType?: SortType) => void,
    onTranssactionDeleted: () => void
}

function ResultsTable({
    paginatedResult,
    isLoading,
    isFetching,
    page,
    sortColumn,
    sortType,
    onPageChange,
    onSortTable,
    onTranssactionDeleted }: ResultsTablePropsType) {

    const { authStore } = useStore()
    if (isLoading) {
        return (
            <div className="w-100 d-flex justify-content-center mt-4">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    if (!isLoading && !paginatedResult) {
        return (
            <h2>Error: no se han podido obtener los datos</h2>
        )
    }

    if (!paginatedResult?.data.length) {
        return (
            <h2>No hay datos para mostrar</h2>
        )
    }

    return (
        <div id="result_table">
            <div className="w-100 border border-dark bg-dark rounded-top text-white p-2 d-flex justify-content-end">
                <div>Resultados obtenidos: {paginatedResult.total}</div>
            </div>
            <Table responsive bordered className="text-center">
                <thead className="bg-dark text-white border border-dark">
                    <tr >
                        <th>Tipo</th>
                        <SortableTableHeader
                            currentSortColumn={sortColumn}
                            name="monto"
                            direction={sortType}
                            onClick={onSortTable}>
                            Monto USD
                        </SortableTableHeader>
                        <th>Método de pago SNC</th>
                        <th>Ref de salida</th>
                        <th>Método entrada</th>
                        <th>Ref de entrada</th>
                        <SortableTableHeader
                            currentSortColumn={sortColumn}
                            name="fecha"
                            direction={sortType}
                            onClick={onSortTable}>
                            Fecha
                        </SortableTableHeader>
                        <SortableTableHeader
                            currentSortColumn={sortColumn}
                            name="tasa"
                            direction={sortType}
                            onClick={onSortTable}>
                            Tasa (Bs)
                        </SortableTableHeader>
                        <SortableTableHeader
                            currentSortColumn={sortColumn}
                            name="conversion"
                            direction={sortType}
                            onClick={onSortTable}>
                            Conversion (Bs)
                        </SortableTableHeader>
                        <th>Comp./Vend.</th>
                        <th>Observación</th>
                        {authStore.user?.is_admin && <th>
                            Acciones
                        </th>}
                    </tr>
                </thead>
                <tbody>
                    {paginatedResult!.data.map(transaction => {
                        return (
                            <tr key={transaction.id}>
                                <td>{transaction.tipo_transaccion?.descripcion}</td>
                                <td className="text-end">{formatNumber(transaction.monto)}</td>
                                <td>{transaction.metodo_salida?.descripcion}</td>
                                <td >
                                    {transaction.referencia_salida}
                                </td>
                                <td>{transaction.metodo_entrada?.descripcion}</td>
                                <td>{transaction.referencia_entrada}</td>
                                <td>{formatDate(transaction.fecha)}</td>
                                <td className="text-end">{formatNumber(transaction.tasa)}</td>
                                <td className="text-end">{formatNumber(transaction.conversion)}</td>
                                <td>{transaction.comprador_vendedor}</td>
                                <td>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 100, hide: 100 }}
                                        overlay={<Tooltip>
                                            {transaction.observacion}
                                        </Tooltip>}
                                    >
                                        <i className="fas fa-eye" />
                                    </OverlayTrigger>
                                </td>
                                {authStore.user?.is_admin && <td>
                                    <ActionButtons transsaction={transaction} onTranssactionDeleted={onTranssactionDeleted} />
                                </td>}
                            </tr>
                        )
                    })}

                </tbody>
            </Table>
            <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: 50 }}>
                {isFetching && <Spinner
                    animation="border"
                    variant="primary" />}
            </div>
            <div className="w-100 d-flex justify-content-center">
                <Paginator
                    pageCount={paginatedResult!.total / APP_SETTINGS.PAGE_SIZE}
                    forcePage={page}
                    onPageChange={onPageChange} />
            </div>
        </div>
    )
}

export default ResultsTable
