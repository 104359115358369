import BalanceSection from "./balance-section"
import FilterTableSection from "./filter-table-section/filter-table-section"

const TransactionsListPàge = () => {
    return (
        <>
            <BalanceSection />
            <FilterTableSection/>
        </>
    )
}

export default TransactionsListPàge