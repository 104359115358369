import { observer } from 'mobx-react-lite'
import React from 'react'
import { Container } from 'react-bootstrap'
import AppNavbar from '../components/app-navbar'

type AuthenticatedLayoutPropsTypes = {
    children?: React.ReactNode
}

const AuthenticatedLayout = ({ children }: AuthenticatedLayoutPropsTypes) => {
    
    return (
        <>
            <AppNavbar/>
            <Container>
                {children}
            </Container>
        </>
    )
}

export default observer(AuthenticatedLayout)
