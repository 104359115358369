import { observer } from 'mobx-react-lite'
import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import APP_ROUTES from '../constants/app-routes'
import useStore from '../hooks/use-store'

const AppNavbar = () => {
    const { authStore } = useStore()

    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to={APP_ROUTES.HOME}>
                    <img
                        src="img/snclogo.svg"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="logo-snc"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to={APP_ROUTES.HOME}>
                            <i className='fas fa-home me-1' />
                            <span>Inicio</span>
                        </Nav.Link>
                        <Nav.Link as={Link} to={APP_ROUTES.LOAD}>
                            <i className='fas fa-upload me-1' />
                            <span>Cargar</span>
                        </Nav.Link>
                        <Nav.Link as={Link} to={APP_ROUTES.REPORT}>
                            <i className='fas fa-file-excel me-1' />
                            <span>Generar reporte</span>
                        </Nav.Link>
                        <Nav.Link as={Link} to={APP_ROUTES.BALANCE}>
                            <i className='fas fa-balance-scale me-1' />
                            <span>Saldos</span>
                        </Nav.Link>
                        {authStore.user?.is_admin && <Nav.Link as={Link} to={APP_ROUTES.DELETED}>
                            <i className='fas fa-trash' />
                            <span>Transacciones Eliminadas</span>
                        </Nav.Link>}
                    </Nav>
                    <Nav className="ml-auto mr-0">
                        <Nav.Link onClick={() => authStore.logout()}>
                            <i className='fas fa-power-off me-1' />
                            Cerrar Sesión
                        </Nav.Link>
                    </Nav>

                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default observer(AppNavbar)
