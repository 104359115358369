import React, { useState } from 'react'
import { Form, Col, Button, Alert, Spinner, Row } from 'react-bootstrap'
import api from '../../api/api'
import FileDownload from 'js-file-download'
import { useGetMetodosEs } from '../../react-query/common-queries'
import ITransaccion from '../../interfaces/service/models/ITransaccion'
import PreviewTable from './preview-table'

const Report = () => {

    const metodosESQuery = useGetMetodosEs()

    const [from, setFrom] = useState<string>("")
    const [to, setTo] = useState<string>("")
    const [metodoEs, setMetodoEs] = useState("0")

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)

    const [previewTransacctions, setPreviewTransacctions] = useState<ITransaccion[] | undefined>(undefined)

    const formRef = React.createRef<HTMLFormElement>()

    function handleFromChange(e: React.ChangeEvent<HTMLInputElement>) {
        setFrom(e.target.value)
    }

    function handleToChange(e: React.ChangeEvent<HTMLInputElement>) {
        setTo(e.target.value)
    }

    function handleMetodoChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setMetodoEs(e.target.value)
    }

    async function getPreview() {
        if (!formRef.current?.checkValidity()) return
        try {
            setIsLoading(true)
            setError(undefined)
            const result = await api.reportes.reporteTransaccionPreview(from, to, metodoEs)
            setPreviewTransacctions(result.data)

        } catch (err) {
            setError("Ocurrió un error al obtener la vista previa")
            throw err
        }
        finally {
            setIsLoading(false)
        }
    }

    async function downloadExcel(e: any) {
        if (!formRef.current?.checkValidity()) return
        try {
            setIsLoading(true)
            setError(undefined)
            const result = await api.reportes.reporteTransaccionExcel(from, to, metodoEs)
            FileDownload(result.data, "tansacciones.xlsx")

        } catch (err) {
            setError("Ocurrió un error al descargar el reporte")
            throw err
        }
        finally {
            setIsLoading(false)
        }
    }

    async function downloadPdf() {
        if (!formRef.current?.checkValidity()) return
        try {
            setIsLoading(true)
            setError(undefined)
            const result = await api.reportes.reporteTransaccionPDF(from, to, metodoEs)
            FileDownload(result.data, "tansacciones.pdf")

        } catch (err) {
            setError("Ocurrió un error al descargar el reporte")
            throw err
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <div className="d-flex flex-column align-items-center">
                <h1>Generar reporte</h1>
                <Form
                    ref={formRef}
                    className="card p-4 bg-light mt-2 d-flex justify-content-center"
                    style={{ minWidth: 300 }}>

                    <Form.Group as={Col} className="mb-3" >
                        <Form.Label>Desde</Form.Label>
                        <Form.Control
                            type="date"
                            value={from}
                            onChange={handleFromChange}
                            required />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3" >
                        <Form.Label>Hasta</Form.Label>
                        <Form.Control
                            type="date"
                            value={to}
                            onChange={handleToChange}
                            required />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3" >
                        <Form.Label>Método</Form.Label>
                        <Form.Select
                            value={metodoEs}
                            disabled={metodosESQuery.isLoading}
                            onChange={handleMetodoChange}>
                            <option value={0}>Todos</option>
                            {!metodosESQuery.isLoading && metodosESQuery.data && metodosESQuery.data.map(metodoEs => {
                                return (
                                    <option
                                        key={metodoEs.id}
                                        value={metodoEs.id}>
                                        {metodoEs.descripcion}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    {isLoading ?
                        <Spinner animation="border" className="m-auto" />
                        :
                        <div>
                            <Button
                                type="submit"
                                className="w-100 mb-2"
                                name="preview"
                                onClick={getPreview}>
                                Vista previa
                            </Button>
                            <Row>
                                <Col>
                                    <Button
                                        type="submit"
                                        className="w-100"
                                        name="excel"
                                        variant="success"
                                        onClick={downloadExcel}>
                                        Excel
                                        <i className='fas fa-download ms-1' />
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type="submit"
                                        className="w-100"
                                        name="pdf"
                                        variant="success"
                                        onClick={downloadPdf}>
                                        PDF
                                        <i className='fas fa-download ms-1' />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    }
                    {error && <Alert variant="danger" className="mt-2">
                        {error}
                    </Alert>}
                </Form>
            </div>
            <div className="mt-5">
                <PreviewTable previewTransacctions={previewTransacctions} />
            </div>
        </>
    )
}

export default Report
