import './axios-config'
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import RootStoreContext from './contexts/root-store-context';
import { BrowserRouter } from 'react-router-dom';
import { RootStore } from './stores/root-store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



// Exportado para que axios tenga acceso a la instancia
export const rootStore = new RootStore();

export const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RootStoreContext.Provider value={rootStore}>
        <BrowserRouter>
          <App />
          <ToastContainer />
        </BrowserRouter>
      </RootStoreContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
