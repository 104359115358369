import React, { createRef } from 'react'
import { Alert, Button, Spinner, Table } from 'react-bootstrap'
import { useGetSaldos } from '../../react-query/common-queries'
import formatNumber from '../../utils/format-number'
import getCurrentDateFormated from '../../utils/getCurrentDateFormated'
import { useReactToPrint } from 'react-to-print'

const BalancePage = () => {

    const { error, isLoading, isFetching, data } = useGetSaldos()

    const ref = createRef<HTMLDivElement>()

    const handlePrint = useReactToPrint({
        content: () => ref.current,
        documentTitle: `Saldos_al_${getCurrentDateFormated()}`
    });

    return (
        <>

            <div className="d-flex justify-content-center">
                <div className="d-flex flex-column align-items-center" ref={ref}>
                    <h1>Saldos al {getCurrentDateFormated()}</h1>
                    {error && <Alert variant="danger">
                        Ocurrió un problema al intentar obtener los saldos
                    </Alert>}
                    <div
                        className="mt-2 d-flex justify-content-center flex-column align-items-center"
                        style={{ minWidth: 300 }}>
                        {(isLoading || isFetching) && <div className="mt-2">
                            <Spinner animation="border" variant="primary" />
                        </div>}
                        {!isLoading && data &&
                            <Table responsive bordered className="text-center" id="result_table">
                                <thead className="bg-dark text-white border border-dark">
                                    <tr>
                                        <th>Método</th>
                                        <th>Entradas</th>
                                        <th>Salidas</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.map(resumenMetodo => {
                                        return (
                                            <tr key={resumenMetodo.id}>
                                                <td>{resumenMetodo.descripcion}</td>
                                                <td className="text-end">
                                                    {formatNumber(resumenMetodo.total_entradas)}
                                                </td>
                                                <td className="text-end">
                                                    {formatNumber(resumenMetodo.total_salidas)}
                                                </td>
                                                <td className="text-end">
                                                    {formatNumber(resumenMetodo.balance)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        }
                    </div>
                </div>
            </div>
            {!isLoading && data && <div className="d-flex justify-content-center my-3">
                <Button onClick={handlePrint}>
                    <span className="me-2">Imprimir</span>
                    <i className="fas fa-print" />
                </Button>
            </div>}
        </>
    )
}

export default BalancePage
