import axios from "axios";
import qs from "qs";
import { REPORTES_TRANSACCIONES_EXCEL, REPORTES_TRANSACCIONES_FILTRADO_EXCEL, REPORTES_TRANSACCIONES_FILTRADO_PDF, REPORTES_TRANSACCIONES_PDF, REPORTES_TRANSACCIONES_PREVIEW } from "../constants/service-resources";

export async function reporteTransaccionExcel(from: string, to: string, metodo: string) {
    const result = await axios.get(REPORTES_TRANSACCIONES_EXCEL, {
        params: {
            from,
            to,
            metodo
        },
        responseType: 'blob'
    })
    return result
}

export async function reporteTransaccionPDF(from: string, to: string, metodo: string) {
    const result = await axios.get(REPORTES_TRANSACCIONES_PDF, {
        params: {
            from,
            to,
            metodo
        },
        responseType: 'blob'
    })
    return result
}

export async function reporteTransaccionPreview(from: string, to: string, metodo: string) {
    const result = await axios.get(REPORTES_TRANSACCIONES_PREVIEW, {
        params: {
            from,
            to,
            metodo
        },
    })
    return result
}

export async function reporteTransaccionFiltradoExcel(orderBy: string = "fecha",
    orderDirection: string = "desc",
    filter?: any) {

    const params: any = {
        orderBy,
        orderDirection,
    }

    if (filter) {
        params.filter = filter
    }

    const result = await axios.get(REPORTES_TRANSACCIONES_FILTRADO_EXCEL, {
        params: params,
        responseType: 'blob',
        paramsSerializer: (pr) => qs.stringify(pr)
    })
    
    return result
}

export async function reporteTransaccionFiltradoPdf(orderBy: string = "fecha",
    orderDirection: string = "desc",
    filter?: any) {

    const params: any = {
        orderBy,
        orderDirection,
    }

    if (filter) {
        params.filter = filter
    }

    const result = await axios.get(REPORTES_TRANSACCIONES_FILTRADO_PDF, {
        params: params,
        responseType: 'blob',
        paramsSerializer: (pr) => qs.stringify(pr)
    })

    return result
}