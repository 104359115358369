import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import APP_ROUTES from '../constants/app-routes'
import Login from '../pages/login/login'

function UnauthenticatedContent() {
    return (
        <Routes>
            <Route path={APP_ROUTES.LOGIN} element={<Login/>}/>
            <Route path="*" element={<Navigate replace to={APP_ROUTES.LOGIN} />} />
        </Routes>
    )
}

export default UnauthenticatedContent
