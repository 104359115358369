import fileDownload from 'js-file-download'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { queryClient } from '../../..'
import api from '../../../api/api'
import RQ from '../../../react-query/queries-constants'
import Filter from './filter'
import ResultsTable from './results-table/results-table'

function FilterTableSection() {
    const [page, setPage] = useState(0)
    const [sortColumn, setSortColumn] = useState<string | undefined>("fecha")
    const [sortType, setSortType] = useState<"asc" | "desc">("desc")
    const [filter, setFilter] = useState<any | undefined>(undefined)
    const [queryMadedBy, setQueryMadedBy] = useState<"filter" | "pagination">("filter")
    const [isDownloadingFile, setIsDownloadingFile] = useState(false)
    const [downloadError, setDownloadError] = useState<string|undefined>(undefined)

    const {
        isLoading,
        data: paginatedResult,
        isFetching,

    } = useQuery([RQ.TRANSSACTIONS_LIST, page, sortColumn, sortType, filter],
        () => api.transacciones.loadTransactions(page + 1, sortColumn, sortType, filter),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            onSuccess: (result) => {
                // Si la petición devolvió datos
                // y no fue hecha mediante un filtro,
                // es decir que fue por un cambio de página,
                // Se hace scroll automaticamente al principio de la tabla.
                if (result.data.length && queryMadedBy === "pagination") {
                    document.getElementById("result_table")?.scrollIntoView()
                }
            },
        }
    )

    function onPageChange(selectedItem: {
        selected: number;
    }) {
        setPage(selectedItem.selected)
        setQueryMadedBy("pagination")
    }

    function onSortTable(dataKey: string, sortType?: "asc" | "desc") {
        setSortColumn(dataKey)
        setSortType(sortType || "desc")
    }

    function onFilter(filterValue: any | undefined) {
        if (isLoading || isFetching) {
            console.error("No se puede filtar mientra se estan obteniendo datos desde el servicio")
            return
        }

        if (filterValue) {
            setFilter({ ...filterValue })
        } else {
            setFilter(filterValue)
        }
        // Volver a consultar siempre la primera página al cambiar el filtro
        setPage(0)

        setQueryMadedBy("filter")
    }

    async function onDownloadExcelClick() {
        try {
            setIsDownloadingFile(true)
            setDownloadError(undefined)
            const result = await api.reportes.reporteTransaccionFiltradoExcel(sortColumn, sortType, filter)
            fileDownload(result.data, "tansacciones.xlsx")

        } catch (err) {
            setDownloadError("Ha ocurrido un error al descargar el archivo Excel")
        }
        finally {
            setIsDownloadingFile(false)
        }
    }

    async function onDownloadPdfClick() {
        try {
            setIsDownloadingFile(true)
            setDownloadError(undefined)
            const result = await api.reportes.reporteTransaccionFiltradoPdf(sortColumn, sortType, filter)
            fileDownload(result.data, "tansacciones.pdf")

        } catch (err) {
            setDownloadError("Ha ocurrido un error al descargar el archivo PDF")
        }
        finally {
            setIsDownloadingFile(false)
        }
    }

    function onTranssactionDeleted(){
        queryClient.invalidateQueries(RQ.GET_RESUMEN_TRANSACCIONES)
        queryClient.invalidateQueries([RQ.TRANSSACTIONS_LIST, page, sortColumn, sortType, filter])
        queryClient.invalidateQueries(RQ.GET_SALDOS)
    }

    return (
        <div className="">
            <Filter
                isLoading={isLoading}
                isFetching={isFetching}
                isDownloadingFile={isDownloadingFile}
                downloadError={downloadError}
                onFilter={onFilter}
                onDownloadExcelClick={onDownloadExcelClick}
                onDownloadPdfClick={onDownloadPdfClick}/>
            <div className="mt-4">
                <ResultsTable
                    paginatedResult={paginatedResult}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    page={page}
                    onPageChange={onPageChange}
                    onSortTable={onSortTable}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onTranssactionDeleted={onTranssactionDeleted}
                />
            </div>
        </div>
    )
}

export default FilterTableSection