import React from 'react'
import { Navigate } from 'react-router-dom'
import APP_ROUTES from '../constants/app-routes'
import useStore from '../hooks/use-store'

type OnlyAdminPropsType = {
    children: JSX.Element
}

const OnlyAdmin = ({ children }: OnlyAdminPropsType) => {
    const { authStore } = useStore()

    if (!authStore.user?.is_admin) {
        return <Navigate to={APP_ROUTES.HOME} />
    }

    return (
        children
    )
}

export default OnlyAdmin
