import React from 'react'

export interface ITableColumnSorterProps {
    currentSortColumn?: string,
    direction: "asc" | "desc",
    name: string
}

function TableColumnSorter({currentSortColumn, direction, name}:ITableColumnSorterProps) {
    if(currentSortColumn && currentSortColumn === name){
        if(direction === "asc"){
            return <i className="fas fa-sort-up text-white"/>
        }
        return <i className="fas fa-sort-down text-white"/>
    }

    return <i className="fas fa-sort text-white"/>
}

export default TableColumnSorter
