import React, { useMemo, useState } from 'react'
import appCurrency from '../utils/app-currency'

type useCurrencyInputReturnType = {
    numericValue: number,
    reset: ()=>void,
    inputProps: {
        value: any,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    }
}

const useCurrencyInput = (): useCurrencyInputReturnType => {

    const [numericValue, setNumericValue] = useState(0.00)

    const value = useMemo(() => {
        return appCurrency(numericValue).format()
    }, [numericValue])

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {

        const nevent = e.nativeEvent as InputEvent
        if (nevent.data !== null) {
            const keyNumValue = parseInt(nevent.data)
            if (!isNaN(keyNumValue)) {

                const toSum = appCurrency(keyNumValue).divide(100).value

                const increased = appCurrency(numericValue).multiply(10).value
                const newValue = appCurrency(increased).add(toSum).value
                setNumericValue(newValue)

            }
        } else {
            // "deleteContentBackward" representa la tecla de borrar.
            // Probando en mozilla y chrome para esxcritorio, y en chrome para android
            if (nevent.inputType === "deleteContentBackward") {
                // "Corre" los números a la derecha. 
                // No se usa currency.js porque redondeará (123,456 => 123,46) y esto no sirve en este caso
                const decreased = numericValue / 10
                // Reducir el resultado a solo 2 decimales (123,456 => 123,45)
                const with2Decimals = decreased.toString().match(/^-?\d+(?:\.\d{0,2})?/)![0]!
                // Convertir de nuevo a un valor númerico
                const finalNewValue = parseFloat(with2Decimals)
                setNumericValue(finalNewValue)
            }
        }
    }

    function reset() {
        setNumericValue(0.00)
    }

    return {
        numericValue,
        reset,
        inputProps: {
            value,
            onChange
        }
        
    }
}

export default useCurrencyInput
