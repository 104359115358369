import APP_ROUTES from "./app-routes";
import {TransactionLoadPage, TransactionsListPage} from '../pages'
import IAppRoute from '../interfaces/app/IAppRoute'
import Report from "../pages/report/report";
import DeletedTransactionsPage from "../pages/transactions-deleted/transactions-deleted";
import OnlyAdmin from "../components/only-admin";
import BalancePage from "../pages/balance/balance";

const AuthenticatedRouting: IAppRoute[] = [
    {
        path: APP_ROUTES.HOME,
        element: <TransactionsListPage/>
    },
    {
        path: APP_ROUTES.LOAD,
        element: <TransactionLoadPage/>
    },
    {
        path: APP_ROUTES.REPORT,
        element: <Report/>
    },
    {
        path: APP_ROUTES.DELETED,
        element: <OnlyAdmin><DeletedTransactionsPage/></OnlyAdmin>,
        onlyAdmin: true
    },
    {
        path: APP_ROUTES.BALANCE,
        element: <BalancePage/>,
    }
]

export default AuthenticatedRouting