import React, { useMemo, useState } from 'react'
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import ErrorMessage from '../../../components/error-message'
import { TRANSACTION_TYPES_ARRAY } from '../../../constants/transactions-types'
import useCurrencyInput from '../../../hooks/use-currency-input'
import { useGetMetodosEs } from '../../../react-query/common-queries'

type FilterState = {
    tipo_transaccion_id: number,
    monto: string,
    metodo_entrada_id: number,
    metodo_salida_id: number,
    referencia_entrada: string,
    referencia_salida: string,
    fecha: string,
    tasa: string,
    conversion: string,
    comprador_vendedor: string,
    observacion: string
}

const initialFilter = {
    tipo_transaccion_id: 0,
    monto: "",
    metodo_entrada_id: 0,
    metodo_salida_id: 0,
    referencia_entrada: "",
    referencia_salida: "",
    fecha: "",
    tasa: "",
    conversion: "",
    comprador_vendedor: "",
    observacion: ""
}

type FilterPropsTypes = {
    isLoading: boolean,
    isFetching: boolean,
    isDownloadingFile: boolean,
    downloadError?: string,
    onFilter: (params: any) => void,
    onDownloadExcelClick: () => Promise<void>
    onDownloadPdfClick: () => Promise<void>
}

function Filter({
    isLoading,
    isFetching,
    isDownloadingFile,
    downloadError,
    onFilter,
    onDownloadExcelClick,
    onDownloadPdfClick }: FilterPropsTypes) {
    const [filter, setFilter] = useState<FilterState>(initialFilter)

    const metodosESQuery = useGetMetodosEs()

    const montoHook = useCurrencyInput()
    const tasaHook = useCurrencyInput()
    const conversionHook = useCurrencyInput()

    const enableSpinner = useMemo(() => {
        return (!isLoading && (isFetching || isDownloadingFile))
    }, [isLoading, isFetching, isDownloadingFile])

    function onSubmit(e: React.FormEvent) {
        e.preventDefault()

        const finalFilter = buildFilter()

        onFilter(finalFilter)
    }

    function buildFilter() {
        const finalFilter: any = {}

        // Indica si por lo menos hay un filtro aplicado
        let atLeastOne = false

        // Colocar en finalFilter solo las propiedades
        // de el objeto filter que sean validas (que no sean cadena vacia o 0)
        Object.keys(filter).forEach((k) => {
            const propValue = filter[k as keyof FilterState]

            if (propValue !== "" && propValue !== "0" && propValue !== 0) {
                atLeastOne = true
                finalFilter[k] = propValue
            }
        })

        if (montoHook.numericValue > 0) {
            finalFilter["monto"] = montoHook.numericValue
            atLeastOne = true
        }

        if (tasaHook.numericValue > 0) {
            finalFilter["tasa"] = tasaHook.numericValue
            atLeastOne = true
        }

        if (conversionHook.numericValue > 0) {
            finalFilter["conversion"] = conversionHook.numericValue
            atLeastOne = true
        }

        if (!atLeastOne) {
            // Sino hay filtros, invocar a onFilter
            // con undefined para que se limpien los filtros en la consulta
            return undefined
        }

        return finalFilter
    }

    function updateFormState(e: any) {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        })
    }

    function onClearClick() {
        montoHook.reset()
        tasaHook.reset()
        setFilter(initialFilter)
        onFilter(undefined)
    }

    return (
        <div>
            <Form onSubmit={onSubmit} className="bg-white border p-4">
                {/* Tipo transaccion */}
                <Row>
                    <Form.Group className="mb-3" as={Col} xs={12} lg={2}>
                        <Form.Label>Tipo de transacción</Form.Label>
                        <Form.Select
                            name="tipo_transaccion_id"
                            value={filter.tipo_transaccion_id}
                            onChange={updateFormState}>
                            <option value={0}>Todos</option>
                            {TRANSACTION_TYPES_ARRAY.map(transactionType => {
                                return <option key={transactionType.value} value={transactionType.value}>
                                    {transactionType.name}
                                </option>
                            })}
                        </Form.Select>
                    </Form.Group>
                    {/* Monto USD */}
                    <Form.Group className="mb-3" as={Col} xs={12} lg={2} controlId="fltMonto">
                        <Form.Label>Monto USD</Form.Label>
                        <Form.Control
                            className="text-end"
                            {...montoHook.inputProps}
                        />
                    </Form.Group>
                    {/* Metodo salida */}
                    <Form.Group className="mb-3" as={Col} xs={12} lg={2}>
                        <Form.Label>Método de pago SNC</Form.Label>
                        <Form.Select
                            name="metodo_salida_id"
                            value={filter.metodo_salida_id}
                            disabled={metodosESQuery.isLoading}
                            onChange={updateFormState}>
                            <option value={0}>Todos</option>
                            {!metodosESQuery.isLoading && metodosESQuery.data && metodosESQuery.data.map(metodoEs => {
                                return (
                                    <option
                                        key={metodoEs.id}
                                        value={metodoEs.id}>
                                        {metodoEs.descripcion}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    {/* Referencia salida */}
                    <Form.Group className="mb-3" as={Col} xs={12} lg={2}>
                        <Form.Label>Referencia salida</Form.Label>
                        <Form.Control
                            type="text"
                            name="referencia_salida"
                            value={filter.referencia_salida}
                            onChange={updateFormState} />
                    </Form.Group>
                    {/* Metodo Entrada */}
                    <Form.Group className="mb-3" as={Col} xs={12} lg={2}>
                        <Form.Label>Método entrada</Form.Label>
                        <Form.Select
                            name="metodo_entrada_id"
                            value={filter.metodo_entrada_id}
                            disabled={metodosESQuery.isLoading}
                            onChange={updateFormState}>
                            <option value={0}>Todos</option>
                            {!metodosESQuery.isLoading && metodosESQuery.data && metodosESQuery.data.map(metodoEs => {
                                return (
                                    <option
                                        key={metodoEs.id}
                                        value={metodoEs.id}>
                                        {metodoEs.descripcion}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    {/* Referencia salida */}
                    <Form.Group className="mb-3" as={Col} xs={12} lg={2}>
                        <Form.Label>Referencia entrada</Form.Label>
                        <Form.Control
                            type="text"
                            name="referencia_entrada"
                            value={filter.referencia_entrada}
                            onChange={updateFormState} />
                    </Form.Group>
                    {/* Fecha */}
                    <Form.Group className="mb-3" as={Col} xs={12} lg={2}>
                        <Form.Label>Fecha</Form.Label>
                        <Form.Control
                            type="date"
                            name="fecha"
                            value={filter.fecha}
                            onChange={updateFormState} />
                    </Form.Group>
                    {/* Tasa */}
                    <Form.Group className="mb-3" as={Col} xs={12} lg={2}>
                        <Form.Label>Tasa</Form.Label>
                        <Form.Control
                            className="text-end"
                            {...tasaHook.inputProps} />
                    </Form.Group>
                    {/* Conversión en Bs */}
                    <Form.Group className="mb-3" as={Col} xs={12} lg={2}>
                        <Form.Label>Conversión en Bs</Form.Label>
                        <Form.Control
                            className="text-end"
                            {...conversionHook.inputProps} />
                    </Form.Group>
                    {/* Comprador/Vendedor */}
                    <Form.Group className="mb-3" as={Col} xs={12} lg={2}>
                        <Form.Label>Comprador/Vendedor</Form.Label>
                        <Form.Control
                            type="text"
                            name="comprador_vendedor"
                            value={filter.comprador_vendedor}
                            onChange={updateFormState} />
                    </Form.Group>
                    <Form.Group className="mb-3" as={Col} xs={12} lg={2}>
                        <Form.Label>Observación</Form.Label>
                        <Form.Control
                            type="text"
                            name="observacion"
                            value={filter.observacion}
                            onChange={updateFormState} />
                    </Form.Group>
                </Row>
                <div>
                    <fieldset disabled={enableSpinner} className="d-flex">
                        <Button type="submit">
                            Filtrar
                        </Button>
                        <Button
                            className="ms-2"
                            variant="secondary"
                            onClick={onClearClick}>
                            Limpiar filtro
                        </Button>
                        <Button
                            variant="success"
                            className="ms-2"
                            onClick={onDownloadExcelClick}>
                            Excel
                            <i className='fas fa-download ms-1' />
                        </Button>
                        <Button
                            variant="success"
                            className="ms-2"
                            onClick={onDownloadPdfClick}>
                            PDF
                            <i className='fas fa-download ms-1' />
                        </Button>
                        {enableSpinner && <Spinner animation="border" className="ms-2" />}
                    </fieldset>
                    {downloadError && <ErrorMessage message={downloadError} className="mt-2"/>}
                </div>
            </Form>
        </div>
    )
}

export default Filter
