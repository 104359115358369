import axios from "axios";
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ME } from "../constants/service-resources";
import IUser from "../interfaces/service/models/IUser";
import ILoginResponse from "../interfaces/service/responses/ILoginResponse";

export async function loginRequest(email: string, password: string){
    const result = await axios.post<ILoginResponse>(AUTH_LOGIN,{
        email,
        password
    })

    return result
}

export async function getCurrentUser(){
    const result = await axios.post<IUser>(AUTH_ME)
    return result
}

export async function logoutUser(){
    const result = await axios.post(AUTH_LOGOUT)
    return result
}