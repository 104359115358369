import { makeAutoObservable } from "mobx"
import api from "../api/api";
import IUser from "../interfaces/service/models/IUser";

const LSKEY = {
    TOKEN: 'token'
}

class AuthStore {
    user?: IUser;
    token?: string
    isLoadingUserData: boolean = false

    constructor() {
        makeAutoObservable(this)
        this.loadFromLocalStorage()
    }

    async login(email: string, password: string) {
        const result = await api.auth.loginRequest(email, password)
        if (result.status === 200) {
            this.token = result.data.access_token
            localStorage.setItem(LSKEY.TOKEN, result.data.access_token)
            this.loadUserData()
        }
        return result
    }

    async logout() {
        this.token = undefined
        localStorage.removeItem(LSKEY.TOKEN)
    }

    async loadFromLocalStorage() {
        const lsToken = localStorage.getItem(LSKEY.TOKEN)
        if (lsToken && lsToken.length) {
            this.token = lsToken
            await this.loadUserData()
        }
    }

    async loadUserData() {
        try {
            this.isLoadingUserData = true
            const result = await api.auth.getCurrentUser();
            this.user = result.data
        } finally {
            this.isLoadingUserData = false
        }
    }
}

export default AuthStore