import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useResumenTransacciones } from '../../react-query/common-queries'
import formatNumber from '../../utils/format-number'

function BalanceSection() {

    const {data, isLoading} = useResumenTransacciones()

    return (
        <>
        <div className="text-center p-5 text-muted">
            {isLoading ? 
            <Spinner animation="border" variant="primary"/>
            :
            <h1>Divisas Totales: {formatNumber(data?.balance)} USD</h1>
            }
        </div>
        </>
    )
}

export default BalanceSection
