function formatDate(value: string | Date) {

    if (typeof value === "string") {
        //Reemplazar los giones (-) con barras (/) para evitar el problema de que Date le reste 1 día a la fecha.
        // eslint-disable-next-line no-useless-escape
        const valorCorregido = value.replace(/-/g, '\/').replace(/T.+/, '');
        return new Intl.DateTimeFormat("es-VE", { month: "2-digit", day: "2-digit", year: "numeric" }).format(new Date(valorCorregido))
    } else {
        return new Intl.DateTimeFormat("es-VE", { month: "2-digit", day: "2-digit", year: "numeric" }).format(value)
    }

}

export default formatDate