import React, { useEffect, useMemo } from 'react'
import { Form, Row, Col, Button, Spinner, Alert } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { TRANSACTIONS_TYPES, TRANSACTION_TYPES_ARRAY } from '../../constants/transactions-types'
import { useGetMetodosEs } from '../../react-query/common-queries'
import ITransaccionRequest from '../../interfaces/service/requests/ITransaccionRequest'
import { usePostTranssaction } from '../../react-query/mutations'
import yup from '../../yup'
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorFeedback from '../../components/error-feedback'
import appCurrency from '../../utils/app-currency'
import useCurrencyInput from '../../hooks/use-currency-input'

const schema = yup.object({
    tipo_transaccion_id: yup.number().required().label("Tipo de Transacción"),

    comprador_vendedor: yup.string().required().label("Comprador/Vendedor"),

    fecha: yup.string().required().label("Fecha"),

    metodo_entrada_id: yup.number().label("Metodo de entrada").nullable(true),

    metodo_salida_id: yup.number().label("Metodo de pago SNC").nullable(true),

    monto: yup.number().required().transform((value, original) => {
        return appCurrency(original).value
    }).label("Monto USD").moreThan(0),
    
    observacion: yup.string().max(100).required().label("Observación"),

    referencia_entrada: yup.string().when("tipo_transaccion_id", {
        is: (value: any) => value !== TRANSACTIONS_TYPES.SALIDA.value,
        then: yup.string().required()
      }).label("Referencia entrada"),

    referencia_salida: yup.string().when("tipo_transaccion_id", {
        is: (value: any) => value !== TRANSACTIONS_TYPES.ENTRADA.value,
        then: yup.string().required()
      }).label("Referencia salida"),

    tasa: yup.number().required().transform((value, original) => {
        return appCurrency(original).value
    }).moreThan(0).label("Tasa"),
});

function TransactionLoadPage() {

    const metodosESQuery = useGetMetodosEs()

    const postTranssactionMutation = usePostTranssaction()

    const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm<ITransaccionRequest>({
        defaultValues: {
            metodo_entrada_id: 1,
            metodo_salida_id: 1,
            tipo_transaccion_id: TRANSACTION_TYPES_ARRAY[0].value
        },

        resolver: yupResolver(schema)
    });

    const montoHook = useCurrencyInput()
    const tasaHook = useCurrencyInput()

    const conversion = useMemo(() => {
        const resultado = appCurrency(montoHook.numericValue).multiply(tasaHook.numericValue)
        
        if (isNaN(resultado.value)) return 0

        return resultado.format()
    }, [montoHook.numericValue, tasaHook.numericValue])

    const tipoTransaccion = watch("tipo_transaccion_id")

    const transaccionEsEntrada = useMemo(() => {
        return tipoTransaccion === TRANSACTIONS_TYPES.ENTRADA.value
    }, [tipoTransaccion])

    const transaccionEsSalida = useMemo(() => {
        return tipoTransaccion === TRANSACTIONS_TYPES.SALIDA.value
    }, [tipoTransaccion])

    useEffect(() => {
        if (transaccionEsEntrada) {
            setValue("metodo_entrada_id", 1)
            setValue("referencia_salida", "")

            setValue("metodo_salida_id", null)
            setValue("referencia_salida","---")
        }
        if (transaccionEsSalida) {
            setValue("referencia_entrada", "")
            setValue("metodo_salida_id", 1)

            setValue("metodo_entrada_id", null)
            setValue("referencia_entrada","---")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transaccionEsEntrada, transaccionEsSalida])

    async function onSubmit(data: ITransaccionRequest) {
        await postTranssactionMutation.mutateAsync(data)
        
        reset()
        montoHook.reset()
        tasaHook.reset()
        window.scrollTo(0, 0)
    }

    return (
        <>
            <div className="text-center mt-5">
                <h1>Cargar Compra/Venta de Divisas</h1>
            </div>
            <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                {postTranssactionMutation.isSuccess && <Alert variant="success">
                    Transacción cargada correctamente
                </Alert>}

                <fieldset disabled={postTranssactionMutation.isLoading}>
                    <Row className="mb-3">
                        <Form.Group as={Col} xs={12} lg={4} >
                            <Form.Label>Tipo de Transacción</Form.Label>
                            <Form.Select
                                isInvalid={!!errors.tipo_transaccion_id}
                                {...register("tipo_transaccion_id", { valueAsNumber: true })}>
                                {TRANSACTION_TYPES_ARRAY.map(transactionType => {
                                    return <option key={transactionType.value} value={transactionType.value}>
                                        {transactionType.name}
                                    </option>
                                })}
                            </Form.Select>
                            <ErrorFeedback error={errors.tipo_transaccion_id} />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} lg={4} >
                            <Form.Label>Monto USD</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.monto}
                                className="text-end"
                                {...register("monto")}
                                {...montoHook.inputProps}
                            />
                            <ErrorFeedback error={errors.monto} />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} lg={4} >
                            <Form.Label>Método de entrada</Form.Label>
                            <Form.Select
                                disabled={metodosESQuery.isLoading || transaccionEsSalida}
                                isInvalid={!!errors.metodo_entrada_id}
                                {...register("metodo_entrada_id")}
                            >
                                {!metodosESQuery.isLoading && metodosESQuery.data && metodosESQuery.data.map((metodoEs, i) => {
                                    return (
                                        <option
                                            key={metodoEs.id}
                                            value={metodoEs.id}>
                                            {metodoEs.descripcion}
                                        </option>
                                    )
                                })}
                                {transaccionEsSalida &&
                                    <option
                                        value="">
                                        ---
                                    </option>
                                }
                            </Form.Select>
                            <ErrorFeedback error={errors.metodo_entrada_id} />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} xs={12} lg={4} >
                            <Form.Label>Referencia de entrada</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.referencia_entrada}
                                disabled={transaccionEsSalida}
                                {...register("referencia_entrada")} />
                            <ErrorFeedback error={errors.referencia_entrada} />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} lg={4} >
                            <Form.Label>Método de pago SNC</Form.Label>
                            <Form.Select
                                disabled={metodosESQuery.isLoading || transaccionEsEntrada}
                                isInvalid={!!errors.metodo_salida_id}
                                {...register("metodo_salida_id")}>
                                {!metodosESQuery.isLoading && metodosESQuery.data && metodosESQuery.data.map(metodoEs => {
                                    return (
                                        <option
                                            key={metodoEs.id}
                                            value={metodoEs.id}>
                                            {metodoEs.descripcion}
                                        </option>
                                    )
                                })}
                                {transaccionEsEntrada &&
                                    <option
                                        value="">
                                        ---
                                    </option>
                                }
                            </Form.Select>
                            <ErrorFeedback error={errors.metodo_salida_id} />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} lg={4} >
                            <Form.Label>Referencia de salida</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.referencia_salida}
                                disabled={transaccionEsEntrada}
                                {...register("referencia_salida")} />
                            <ErrorFeedback error={errors.referencia_salida} />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} xs={12} lg={4} >
                            <Form.Label >Fecha</Form.Label>
                            <Form.Control
                                type="date"
                                isInvalid={!!errors.fecha}
                                {...register("fecha")} />
                            <ErrorFeedback error={errors.fecha} />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} lg={4} >
                            <Form.Label>Tasa de cambio</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.tasa}
                                className="text-end"
                                {...register("tasa")}
                                {...tasaHook.inputProps} />
                            <ErrorFeedback error={errors.tasa} />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} lg={4} >
                            <Form.Label>Conversión en Bs</Form.Label>
                            <Form.Control
                                disabled
                                value={conversion}
                                className="text-end" />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} xs={12} lg={4} >
                            <Form.Label>Comprador/Vendedor</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.comprador_vendedor}
                                {...register("comprador_vendedor")} />
                            <ErrorFeedback error={errors.comprador_vendedor} />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} lg={4} controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Observación</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                maxLength={100}
                                isInvalid={!!errors.observacion}
                                {...register("observacion")} />
                            <ErrorFeedback error={errors.observacion} />
                        </Form.Group>
                    </Row>

                    <div className="d-flex align-items-center">
                        <Button
                            variant="primary"
                            type="submit"
                            className="me-2">
                            Guardar
                        </Button>
                        {postTranssactionMutation.isLoading && <Spinner animation="border" variant="primary" />}
                    </div>
                </fieldset>
            </Form>
        </>
    )
}

export default TransactionLoadPage
