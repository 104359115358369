import { observer } from 'mobx-react-lite';
import React from 'react';
import './app.scss';
import AuthenticatedContent from './components/authenticated-content';
import UnauthenticatedContent from './components/unauthenticated-content';
import useStore from './hooks/use-store';

function App() {
  const { authStore } = useStore()

  if(authStore.token){
    return <AuthenticatedContent/>
  }

  return (
    <UnauthenticatedContent/>
  );
}

export default observer(App);
