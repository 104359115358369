import React from 'react'
import ReactPaginate from 'react-paginate';

type PaginatorPropsTypes = {
    pageCount: number,
    onPageChange: (selectedItem: {
        selected: number;
    }) => void,
    forcePage: number
}

const Paginator = ({ pageCount, onPageChange, forcePage }: PaginatorPropsTypes) => {

    return (
        <div className="text-center">
            <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Siguiente"}
                breakLabel={'...'}
                breakClassName={'page-item page-link'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                containerClassName={'pagination'}
                activeClassName={'active'}
                pageClassName={'page-item'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                disabledClassName={'disabled'}
                forcePage={forcePage}
            />
        </div>
    )
}

export default Paginator