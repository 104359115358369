import React from 'react'
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import ITransaccion from '../../interfaces/service/models/ITransaccion'
import formatDate from '../../utils/format-date'
import formatNumber from '../../utils/format-number'

type PreviewTablePropsType = {
    previewTransacctions?: ITransaccion[]
}

const PreviewTable = ({ previewTransacctions }: PreviewTablePropsType) => {

    if (!previewTransacctions) return null

    return (
        <Table responsive bordered className="text-center">
            <thead className="bg-dark text-white border border-dark">
                <tr >
                    <th>Tipo</th>
                    <th>Monto USD</th>
                    <th>Método de pago SNC</th>
                    <th>Ref de salida</th>
                    <th>Método entrada</th>
                    <th>Ref de entrada</th>
                    <th>Fecha</th>
                    <th>Tasa (Bs)</th>
                    <th>Conversion (Bs)</th>
                    <th>Comp./Vend.</th>
                    <th>Observación</th>
                </tr>
            </thead>
            <tbody>
                {previewTransacctions.map(transaction => {
                    return (
                        <tr key={transaction.id}>
                            <td>{transaction.tipo_transaccion?.descripcion}</td>
                            <td className="text-end">{formatNumber(transaction.monto)}</td>
                            <td>{transaction.metodo_salida?.descripcion}</td>
                            <td >
                                {transaction.referencia_salida}
                            </td>
                            <td>{transaction.metodo_entrada?.descripcion}</td>
                            <td>{transaction.referencia_entrada}</td>
                            <td>{formatDate(transaction.fecha)}</td>
                            <td className="text-end">{formatNumber(transaction.tasa)}</td>
                            <td className="text-end">{formatNumber(transaction.conversion)}</td>
                            <td>{transaction.comprador_vendedor}</td>
                            <td>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 100, hide: 100 }}
                                    overlay={<Tooltip>
                                        {transaction.observacion}
                                    </Tooltip>}
                                >
                                    <i className="fas fa-eye" />
                                </OverlayTrigger>
                            </td>
                        </tr>
                    )
                })}

            </tbody>
        </Table>
    )
}

export default PreviewTable
