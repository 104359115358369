import React from 'react'
import SortType from '../interfaces/app/SortType'
import TableColumnSorter, { ITableColumnSorterProps } from './table-column-sorter'

interface ISortableTableHeader extends ITableColumnSorterProps {
    children: any,
    onClick?: (dataKey:string, sortType: SortType) => void
}

function SortableTableHeader(props: ISortableTableHeader) {
    return (
        <th onClick={()=>{
            if(props.onClick){
                let direction: SortType = "desc"
                if(props.currentSortColumn === props.name){
                    if(props.direction === "desc"){
                        direction = "asc"
                    }
                }
                props.onClick(props.name,direction)
            }
        }}>
            {props.children}
            <TableColumnSorter {...props}/>
        </th>
    )
}

export default SortableTableHeader
