import React, { useState } from 'react'
import { OverlayTrigger, Tooltip, Spinner, Table } from 'react-bootstrap'
import Paginator from '../../components/paginator'
import formatNumber from '../../utils/format-number'
import formatDate from '../../utils/format-date'
import { useTransaccionesEliminadas } from '../../react-query/common-queries'
import APP_SETTINGS from '../../constants/app-settings'


function ResultsTable() {


    const [page, setPage] = useState(0)

    const { isLoading, data } = useTransaccionesEliminadas(page+1)


    function onPageChange(selectedItem: {
        selected: number;
    }) {
        setPage(selectedItem.selected)
    }

    if (isLoading) {
        return (
            <div className="w-100 d-flex justify-content-center mt-4">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    if (!isLoading && !data) {
        return (
            <h2>Error: no se han podido obtener los datos</h2>
        )
    }

    if (!data?.data.length) {
        return (
            <h2>No hay datos para mostrar</h2>
        )
    }

    return (
        <>
            <Table responsive bordered className="text-center" id="result_table">
                <thead className="bg-dark text-white border border-dark">
                    <tr >
                        <th>Tipo</th>
                        <th>Monto USD</th>
                        <th>Método de pago SNC</th>
                        <th>Ref de salida</th>
                        <th>Método entrada</th>
                        <th>Ref de entrada</th>
                        <th>Fecha</th>
                        <th>Tasa (Bs)</th>
                        <th>Conversion (Bs)</th>
                        <th>Comp./Vend.</th>
                        <th>Observación</th>
                    </tr>
                </thead>
                <tbody>
                    {data.data.map(transaction => {
                        return (
                            <tr key={transaction.id}>
                                <td>{transaction.tipo_transaccion?.descripcion}</td>
                                <td className="text-end">{formatNumber(transaction.monto)}</td>
                                <td>{transaction.metodo_salida?.descripcion}</td>
                                <td >
                                    {transaction.referencia_salida}
                                </td>
                                <td>{transaction.metodo_entrada?.descripcion}</td>
                                <td>{transaction.referencia_entrada}</td>
                                <td>{formatDate(transaction.fecha)}</td>
                                <td className="text-end">{formatNumber(transaction.tasa)}</td>
                                <td className="text-end">{formatNumber(transaction.conversion)}</td>
                                <td>{transaction.comprador_vendedor}</td>
                                <td>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 100, hide: 100 }}
                                        overlay={<Tooltip>
                                            {transaction.observacion}
                                        </Tooltip>}
                                    >
                                        <i className="fas fa-eye" />
                                    </OverlayTrigger>
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </Table>
            <div className="w-100 d-flex justify-content-center">
                <Paginator
                    pageCount={data.total / APP_SETTINGS.PAGE_SIZE}
                    forcePage={page}
                    onPageChange={onPageChange} />
            </div>
        </>
    )
}

export default ResultsTable
