export const BASE_URL = process.env.REACT_APP_API_URL

export const AUTH = BASE_URL + '/auth'
export const AUTH_LOGIN = AUTH + '/login'
export const AUTH_LOGOUT = AUTH + '/logout'
export const AUTH_ME = AUTH + '/me'

export const TRANSACTIONS = BASE_URL + '/transacciones'
export const TRANSACTIONS_GET_DELETED = TRANSACTIONS + '/eliminadas'

export const METODOS_ES = BASE_URL + '/metodos_es'

export const RESUMEN = BASE_URL + '/resumen'

export const REPORTES = BASE_URL + '/reportes'
export const REPORTES_TRANSACCIONES = REPORTES + '/transacciones'
export const REPORTES_TRANSACCIONES_EXCEL = REPORTES_TRANSACCIONES + '/excel'
export const REPORTES_TRANSACCIONES_PDF = REPORTES_TRANSACCIONES + '/pdf'
export const REPORTES_TRANSACCIONES_PREVIEW = REPORTES_TRANSACCIONES + '/preview'
export const REPORTES_TRANSACCIONES_FILTRADO_EXCEL = REPORTES_TRANSACCIONES + '/filtro/excel'
export const REPORTES_TRANSACCIONES_FILTRADO_PDF = REPORTES_TRANSACCIONES + '/filtro/pdf'

export const SALDOS = BASE_URL + '/saldos'