export interface ITransactionType {
    name: string,
    value: number
}

const COMPRA: ITransactionType = {
    name: "Compra",
    value: 1
}
const VENTA: ITransactionType = {
    name: "Venta",
    value: 2
}
const ENTRADA: ITransactionType = {
    name: "Entrada",
    value: 3
}
const SALIDA: ITransactionType = {
    name: "Salida",
    value: 4
}
const CAMBIO: ITransactionType = {
    name: "Cambio",
    value: 5
}

export const TRANSACTIONS_TYPES = {
    COMPRA,
    VENTA,
    ENTRADA,
    SALIDA,
    CAMBIO,
}

export const TRANSACTION_TYPES_ARRAY = [
    COMPRA,
    VENTA,
    ENTRADA,
    SALIDA,
    CAMBIO
]
